body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Fira Code', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
font-family: 'Bree Serif', serif;
font-family: 'DM Serif Display', serif;
font-family: 'Fira Code', monospace;
font-family: 'Koulen', cursive;
font-family: 'Major Mono Display', monospace;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Paytone One', sans-serif;

font-family: 'Odibee Sans', cursive;
font-family: 'Smooch', cursive;
*/